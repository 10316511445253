export const REF_PORTES = [
    "202451_TD 90 PNEU",
    "202453_TG 90 PNEU",
    "202454_PG 120 PNEU",
    "202455_TD 120 PNEU",
    "202456_PD_120_PNEU",
    "202457_TG 120 PNEU",
    "202476_TG 90 MANU",
    "202477_TD 90 MANU",
    "202478_PD 90 MANU",
    "202488_TG 120 MANU",
    "202489_TD 120 MANU",
    "202490_PD 120 MANU",
    "202491_PG 120 MANU",
    "ITEL_pneu_TD",
    "ITEL_pneu_TG",
    "Albatros_TD",
    "Albatros_TG",
    "RFD60_TD INDUS STD",
    "RFD60_TG INDUS STD",
]

export const REF_BAIES = [
    "950096_BAIE 1200 x 900",
    "950111_BAIE 1800 x 1200",
    "950112_BAIE 1500 x 1200",
    "Albatros 1200 x 800",
    "Albatros 1575 x 1200",
    "Albatros 1800 x 1000",
]

/*
Portes

*/