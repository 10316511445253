import './Archives.css';
import Checkbox from '@mui/material/Checkbox';
import { IconButton, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import * as React from 'react';
import MUIDataTable from 'mui-datatables';
import { BACKEND } from '../../utils';
import RestoreIcon from '@mui/icons-material/Restore';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
function Archives( {stockSelector, findChantier, chantiersNameList } ) {

  
  
  const columns = [{
      name: 'id',
      label: 'Identifiant'
    },
    {
      name: 'ref',
      label: 'Référence',
    },
    {
      name: 'occas',
      label: 'Occas.',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Checkbox disabled readOnly checked={value}></Checkbox>
          );
        }
      }
    },
    {
      name: 'chantierNomChantier',
      label: 'Chantier',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const ID = tableMeta.rowData[0];
          return (
            <TextField
              value={chantierValues[ID]?.name || value}
              readOnly
            />
          );
        }
      }
    },
    {
      name: 'comment',
      label: 'Commentaire',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <TextField disabled sx={{ minWidth: "15vw" }} multiline fullWidth rows={2} value={value}></TextField>
          );
        }
      }
      
    },
    {
      name: 'actions',
      label: 'actions',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let rowData = tableMeta.rowData;
          let ID = rowData[0];
          return (
            <React.Fragment>
              <IconButton color="primary" variant="outlined" onClick={() => handleRestore(ID)} ><RestoreIcon/></IconButton>
              <IconButton color="error" variant="outlined" onClick={() => handleDelete(ID)}><DeleteForeverIcon/></IconButton>
            </React.Fragment>
          );
        } 
      }
    }
  ]


  const options = {
    filterType: 'textField',
    responsive: 'standard',
    selectableRows: 'none',
  }

  //restaurer
  const handleRestore = (ID) => {
    const confirmed = window.confirm("Sortie des archives de l'objet : " + ID)
    if (!confirmed){
      return;
    }
    const item = collec.find(item => item.id === ID);
    fetch(BACKEND + '/api/stock/' + item.type + '/sortie', {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: ID,
        value: 1  // or any value that indicates the item is not archived anymore
      })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Error restoring item');
      }
      // Update state directly by removing the item from the 'collec' state
      const updatedCollec = collec.filter(item => item.id !== ID);
      setCollec(updatedCollec);
    })
    .catch(error => console.log(error.message));
  }
  

  const handleDelete = (ID) => {
    const confirmed = window.confirm("Confirmez la suppression définitive de l'objet " + ID);
    if (!confirmed){
      return;
    }
    const item = collec.find(item => item.id === ID);
    fetch(BACKEND + '/api/stock/' + item.type + '/delete', {
      method: 'DELETE',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: ID,
        // any other data needed for the delete operation
      })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Error deleting item');
      }
      // Update state directly by removing the item from the 'collec' state
      const updatedCollec = collec.filter(item => item.id !== ID);
      setCollec(updatedCollec);
    })
    .catch(error => console.log(error.message));
  }
  



  const [collec, setCollec] = useState();

  useEffect(() => {
    async function fetchData() {
      const response = await fetch(BACKEND + '/api/stock/archives');
      const data = await response.json();
      setCollec(data);
    }
    fetchData();
  }, []);

  const [chantierValues, setChantierValues] = useState({})

  useEffect(() => {
    if(!collec){
      return;
    }
    const newChantierValues = collec.reduce((acc, item) => {
      const date = findChantier(item.chantierNomChantier);
      acc[item.id] = { name: item.chantierNomChantier, date };
      return acc;
    }, {});
    setChantierValues(newChantierValues);
  }, [collec, findChantier]);
  

  if (!collec || collec.length === 0) {
    return <h1>Chargement...</h1>;
  }else{
    console.log(collec)
  }
  return (
    <div className="StockTab">
          
      <MUIDataTable title="Archives ACR" data={collec} columns={columns} options={options}/>
    </div> 
  );
}

export default Archives;
