import './BonDeCommande.css';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from 'react';
import { Autocomplete, TextField, Checkbox, Button, IconButton } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { REF_PORTES, REF_BAIES} from './references'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DeleteIcon from '@mui/icons-material/Delete';
import { BACKEND } from '../../utils';

function BonDeCommande() {
    
    const [collecNewPortes, setCollecNewPortes] = useState([]);
    const [collecNewBaies, setCollecNewBaies] = useState([]);
    const [idCommande, setIdCommande] = useState("");
    const [disableTextfield, setDisableTextField] = useState(false);

    const handleId = (id) => {
        setIdCommande(id.toUpperCase());
    }

    const handleAddPorte = () => {
        if(idCommande === "") {
            alert("Veuillez entrer un ID de commande");
            return;
        }

        setDisableTextField(true);

        const newPorte = {
            "id" : idCommande + "-P" + (formatNumbers(collecNewPortes.length)),
            "ref": "",
            "occas" : false
        };
        setCollecNewPortes([...collecNewPortes, newPorte]);
    }

    const formatNumbers = (collecLength) => {
        collecLength += 1;
        if(collecLength < 10){
            return "00" + collecLength;
        } else if (collecLength < 100) {
            return "0" + collecLength;
        }else {
            return "" + collecLength;
        }
    }

    const handleDeletePorte = (id) => {
        let newCollec = collecNewPortes.filter((porte) => porte.id !== id);
        setCollecNewPortes(newCollec);
    }


    const handlePorteRefChange = (id, newValue) => {
        const updatedPortes = collecNewPortes.map((porte) => {
            if (porte.id === id) {
                return { ...porte, ref: newValue };
            }
            return porte;
        });
        setCollecNewPortes(updatedPortes);
    };

    const handlePorteOccasChange = (id, newValue) => {
        const updatedPortes = collecNewPortes.map((porte) => {
            if (porte.id === id) {
                return { ...porte, occas: newValue };
            }
            return porte;
        });
        setCollecNewPortes(updatedPortes);
    };

    const handleAddBaie = () => {
        if(idCommande === "") {
            alert("Veuillez entrer un ID de commande");
            return;
        }

        setDisableTextField(true);

        const newBaie = {
            "id" : idCommande + "-B" + (formatNumbers(collecNewBaies.length)),
            "occas": false,
            "ref": "",
        };
        setCollecNewBaies([...collecNewBaies, newBaie]);
    }

    const handleDeleteBaie = (id) => {
        let newCollec = collecNewBaies.filter((baie) => baie.id !== id);
        setCollecNewBaies(newCollec);
    }

    const handleBaieRefChange = (id, newValue) => {
        const updatedBaies = collecNewBaies.map((baie) => {
            if (baie.id === id) {
                return { ...baie, ref: newValue };
            }
            return baie;
        });
        setCollecNewBaies(updatedBaies);
    };

    const handleBaieOccasChange = (id, newValue) => {
        const updatedBaies = collecNewBaies.map((baie) => {
            if (baie.id === id) {
                return { ...baie, occas: newValue };
            }
            return baie;
        });
        setCollecNewBaies(updatedBaies);
    };
    
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        async function fetchData() {
          const response = await fetch(BACKEND + '/api/commandes');
          const data = await response.json();
          setOrders(data.orders);
        }
        fetchData();
      }, []);

      const checkIfIdExists = (id) => { 
        const idExists = orders.find((order) => order.id === id);
        return idExists;
      }

      const checkIfPortesOrBaiesEmptyRef = () => {
        
        for (let i = 0; i < collecNewPortes.length; i++) {
            if (collecNewPortes[i].ref === "") {
                return true;
            }
        }
        for (let i = 0; i < collecNewBaies.length; i++) {
            if (collecNewBaies[i].ref === "") {
                return true;
            }
        }
        return false;
      }

    const resetForm = () => {
        setCollecNewPortes([]);
        setCollecNewBaies([]);
        setIdCommande("");
        setDisableTextField(false);
    }

    const handleValider = () => {
        if (collecNewPortes.length === 0 && collecNewBaies.length === 0) {
            alert("Veuillez ajouter au moins une porte ou une baie");
            return;
        }

        if(checkIfIdExists(idCommande)){
            alert("Cette    \"ID DE COMMANDE\"    existe déjà");
            return;
        }

        if(checkIfPortesOrBaiesEmptyRef()){
            alert("Veuillez remplir les références des portes et des baies");
            return;
        }
        
        const newOrder = {
            id: idCommande,
            portes: collecNewPortes,
            baies: collecNewBaies,
        };
        
        fetch(BACKEND + '/api/commandes', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(newOrder),
        });
        
        //format the data for the frontend
        const newOrderFormated = {
            id: newOrder.id,
            portes: newOrder.portes.map((porte) => {
                return ({ id: porte.id, ref: porte.ref })
            }),
            baies: newOrder.baies.map((baie) => {
                return ({ id: baie.id, ref: baie.ref })
            }),
        }
        console.log(newOrderFormated);
        setOrders([...orders, newOrderFormated]);
        resetForm();
    }

    const handleCancel = () => {
        if (collecNewPortes.length > 0 || collecNewBaies.length > 0) {
            const confirmation = window.confirm("Voulez-vous vraiment effacer les données saisies ?");
            if (confirmation) {
                resetForm();
            }
        }
    }
        
    
 
    const handleDeleteOrder = (orderId) => {
        //ask to type orderId before deleting
        const prompt = window.prompt("Veuillez confirmer l'ID de la commande à supprimer (" + orderId + ")");
        if (prompt === orderId) {
            //delete the order from the backend
            fetch(BACKEND + '/api/commandes', {
                method: 'DELETE',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  id: orderId,
                  })
              })
            const updatedOrders = orders.filter((order) => order.id !== orderId);
            setOrders(updatedOrders);
        }else{
            alert("L'ID saisi n'est pas correcte");
        }
    }

    const [expandedOrderId, setExpandedOrderId] = useState(null);

    const toggleOrderExpansion = (orderId) => {
        if (expandedOrderId === orderId) {
            setExpandedOrderId(null);
        } else {
            setExpandedOrderId(orderId);
        }
    };
    

  return (
    
    <div className="BonDeCommande">
      <div className="addBonDeCommande">
        <div className='addTitle'>Nouvelle Commande</div>
        
        <div className='addButtons'>
            <TextField disabled={disableTextfield} value={idCommande} size='small' className='ID' label="ID Commande" variant="outlined" onChange={(e) => handleId(e.target.value)} />
            <Button sx={{marginLeft: "10px"}} onClick={handleAddPorte} variant="contained" startIcon={<AddIcon />}>Nouvelle porte</Button>
            <Button sx={{marginLeft: "10px"}} onClick={handleAddBaie} variant="contained" startIcon={<AddIcon />}>Nouvelle baie</Button>
        </div>
        
        <div className='portesList'>
            {collecNewPortes.map((item) => (
                <div className='porte' key={item["id"]}>
                    <div className={'cardHeader porteHeader'}>
                        <div className='cardId'>{item["id"]}</div>
                        {(item["id"] === collecNewPortes.slice(-1)[0]["id"]) ? (<IconButton onClick={()=>handleDeletePorte(item["id"])} color="error" ><RemoveCircleOutlineIcon fontSize='small' /></IconButton>) : (<div className="blank"></div>)}
                    </div>
                    <div className='fields'>
                        {<Autocomplete 
                                disablePortal 
                                options={REF_PORTES} 
                                sx={{ width: "100%" }} 
                                onChange={(event, newValue) => handlePorteRefChange(item.id, newValue)}
                                renderInput={(params) => <TextField {...params} label="Référence" />}
                            />}
                        <FormGroup>
                            <FormControlLabel 
                                control={<Checkbox color="success" sx={{margin: "0px", padding: "0px"}} checked={item["occas"]} onChange={(event) => handlePorteOccasChange(item.id, event.target.checked)}/>}
                                sx={{margin: "0px", padding: "0px"}}
                                label="Occas."
                                labelPlacement='top'
                            />
                        </FormGroup>
                    </div>
                </div>
            ))
            }
        </div>
        <div className='baiesList'>
            {collecNewBaies.map((item) => (
                <div className='baie' key={item["id"]}>
                    <div className='cardHeader baieHeader'>
                        <div className='cardId'>{item["id"]}</div>
                        {(item["id"] === collecNewBaies.slice(-1)[0]["id"]) ? <IconButton onClick={()=>handleDeleteBaie(item["id"])}  color="error" ><RemoveCircleOutlineIcon fontSize='small' /></IconButton> : (<div className="blank"></div>)}
                    </div>
                    <div className='fields'>
                        {<Autocomplete 
                            disablePortal 
                            options={REF_BAIES} 
                            sx={{ width: "100%" }} 
                            renderInput={(params) => <TextField {...params} label="Référence" />}
                            onChange={(event, newValue) => handleBaieRefChange(item.id, newValue)}
                        />
                        }
                        <FormGroup>
                            <FormControlLabel 
                                control={<Checkbox color="success" sx={{margin: "0px", padding: "0px"}} checked={item["occas"]} onChange={(event) => handleBaieOccasChange(item.id, event.target.checked)}/>}
                                sx={{margin: "0px", padding: "0px"}}
                                label="Occas."
                                labelPlacement='top'
                            />
                        </FormGroup>
                    </div>   
                </div>
            ))
            }
        </div>
        <Button variant="contained" color='error' onClick={()=>handleCancel()}>Annuler</Button>
        <Button sx={{marginLeft: "2vw"}} variant="contained" color="success" onClick={()=>handleValider()}>Valider</Button>
      </div>
      <div className='ordersList'>
      <div className='addTitle'>Liste des commandes</div>
      {orders.map((order) => (
        <div className="order" key={order.id}>
            <div className="orderHeader">
                <div className="orderIdWrapper" onClick={() => toggleOrderExpansion(order.id)}>
                {expandedOrderId === order.id ? <ExpandLessIcon fontSize="small" className="expandIcon"/> : <ExpandMoreIcon fontSize="small" className="expandIcon"/>}
                    <span className="orderId">{order.id}</span>
                </div>
                <div className="orderActions">
                    <IconButton onClick={() => handleDeleteOrder(order.id)} color="error">
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </div>
            </div>
            {expandedOrderId === order.id && (
                <div className="orderItems">
                    <div className='orderPortes'>
                        {order.portes.map((porte) => (
                            <div className='orderPorte' key={porte.id}><b>{porte.id}</b> | {porte.ref}</div>
                        ))}
                    </div>
                    <div className='orderBaies'>
                        {order.baies.map((baie) => (
                            <div className='orderBaie' key={baie.id}><b>{baie.id}</b> | {baie.ref}</div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    ))}
        </div>
    </div>
  );
}

export default BonDeCommande;