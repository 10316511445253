import { IconButton, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import dayjs from 'dayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import './CreateChantier.css';
import { useState } from 'react';

function CreateChantier( {collec, addChantier, getChantiersNameList} ) {
    const [chantier, setChantier] = useState("");
    const [date, setDate] = useState("");

    function handleChangeChantier(e){
        setChantier(e.target.value);
    }

    function handleChangeDate(e){
        let date = dayjs(e).format('YYYY-MM-DD');
        setDate(date);
    }

    function handleClick(){
        if(chantier === ""){
            alert("Veuillez saisir un nom de chantier");
            return;
        }
        
        for(let e of getChantiersNameList()){
            if (e.label === chantier){
                window.alert("Ce chantier existe déjà");
                return;
            }
        }

        addChantier(chantier, date);
        clearFields();
    }

    const clearFields = () => {
        setChantier("");
        setDate("");
    }

  return ( 
    <div className="createChantier">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TextField value={chantier} onChange={(e) => handleChangeChantier(e)} variant="outlined" id="standard-basic" label="Nom du chantier" />
        <MobileDatePicker value={date || null} onChange={(e) => handleChangeDate(e)} sx={{margin : "1vh"}} />
        <IconButton color='primary' onClick={() => handleClick()} variant="contained"><AddIcon /></IconButton>
        </LocalizationProvider>
    </div> 
  );
}

export default CreateChantier;
