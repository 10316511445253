import './App.css';
import Header from './components/Header/Header';
import Stock from './components/Stock/Stock';
import Chantiers from './components/Chantiers/Chantiers';
import { useEffect, useState } from 'react';
import BonDeCommande from './components/BonDeCommande/BonDeCommande';
import { BACKEND } from './utils';
import Login from './components/Login/Login';
import Archives from './components/Archives/Archives';
import Forgot from './components/Forgot/Forgot';
function App() {
  
  const [user, setUser] = useState(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
  
    if (storedUser) {
      const loginDate = new Date(storedUser.date);
      const currentDate = new Date();
      const oneDay = 24 * 60 * 60 * 1000;
  
      if ((currentDate - loginDate) > oneDay) {
        return null;
      } 
    }
  
    return storedUser || null;
  });

    useEffect(() =>{
      localStorage.setItem("user", JSON.stringify(user))
      
    }, [user]);

    // CHANTIERS =============================================================
    const [collecChantiers, setCollecChantiers] = useState([]);
    
    useEffect(() => {
      async function fetchData() {
        updateChantierList();
      }
      fetchData();
    }, []);

    async function updateChantierList() {
      const response = await fetch(BACKEND + '/api/chantierPlus');
      const data = await response.json(); 
      console.log(data)
      setCollecChantiers(data);
    }
   
    function addChantier(nom, date){
      // /api/chantiers (post)
      var newChantier = {
        "nomChantier": nom,
        "dateD": date,
        "stockPortes" : [],
        "stockBaies" : []
      };
      fetch(BACKEND + '/api/chantiers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newChantier),
      })
      setCollecChantiers([...collecChantiers, newChantier]);
    }

    const handleDeleteChantier = (nomChantier) => {
      const confirmation = window.confirm("Voulez vous vraiment supprimer " + nomChantier);
      if(!confirmation){
        return;
      }
      const newData = [...collecChantiers];
      const rowIndex = newData.findIndex((item) => item.nomChantier === nomChantier);
      if (rowIndex === -1) return;
      
      console.log("DELETE rowIndex: " + rowIndex + " nom: " + nomChantier);
      newData.splice(rowIndex, 1);
      setCollecChantiers(newData);
      
      //delete chantier in the API
      fetch(BACKEND + '/api/chantiers', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          nomChantier: nomChantier,
          })
      }) 
    }

    const handleChangeChantierDate = (date, nomChantier) => {
      const formatedDate = date.format('YYYY/MM/DD');
      const newData = [...collecChantiers];
      const rowIndex = newData.findIndex((item) => item.nomChantier === nomChantier);
      if (rowIndex === -1) return;
      newData[rowIndex].dateD = formatedDate;
      //update chantier in the API
      fetch(BACKEND + '/api/chantiers', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            nomChantier: nomChantier,
            dateD: formatedDate,
            })
      })
      setCollecChantiers(newData);
    };

    function getChantiersNameList() {
      var chantiersNameList = [];
      collecChantiers.forEach((item) => {
        let chantier = {
          "label": item["nomChantier"],
        }
        chantiersNameList.push(chantier);
      });
  
      return chantiersNameList;
    }

    function findChantier(chantier) {
      var dateD = null;
          
      collecChantiers.forEach((item) => {
        if (item["nomChantier"] === chantier) {
          dateD = item["dateD"];
        }
      });
      return dateD;
    }


    //RENDERING
    const [display, setDisplay] = useState("stock")
    // eslint-disable-next-line no-unused-vars
    
    
    function render(){
      if(user === null){
        const url = window.location.href;
        if(url.includes("forgot")){
          return (<Forgot/>)
        }else{
          return (<Login
            setUser={setUser}
            />);
        }
      } 

      switch(display) {
        case "stock":
          return <Stock 
                    getChantiersNameList={getChantiersNameList}
                    findChantier={findChantier}
                  />
        case "chantiers":
          return <Chantiers 
                    collecChantiers={collecChantiers} 
                    setCollecChantiers={setCollecChantiers}
                    addChantier={addChantier} 
                    handleDeleteChantier={handleDeleteChantier}
                    handleChangeChantierDate={handleChangeChantierDate}
                    getChantiersNameList={getChantiersNameList}
                  />
        case "commandes":
          return <BonDeCommande/>
        case "archives" :
          return <Archives
                    chantiersNameList={getChantiersNameList}
                    findChantier={findChantier}
                  />
        default:
          break;
      }
    }
    
  return (
    <div className="App">
      {user !== null ? <Header 
                    setDisplay={setDisplay} 
                    user={user}
                    setUser={setUser}
                    updateChantierList={updateChantierList}
                    /> : null }
      {render()}

    </div>
  );
}

export default App;
