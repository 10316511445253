import { Button, TextField, Typography } from '@mui/material';
import './Forgot.css';
import React, { useState } from 'react';
import { BACKEND } from '../../utils';

function Forgot({setUser}) {

  const [password, setPassword] = useState('');

  const queryParameters =  new URLSearchParams(window.location.search)
  const [token] = useState(queryParameters.get("token"));

  const handleReset = (e) => {
    e.preventDefault();
    if(!token){
      alert('token not set')
      return;
    }
    if(password.length<8){
      alert("Le mot de passe doit faire minimum 8 caractères");
      return;
    }
    fetch(BACKEND + "/api/auth/reset" , {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        },
      body: JSON.stringify({
        token: token,
        password: password
        })
      }).then(response => 
        response.json().then(data => ({
            data: data,
            status: response.status
       })))
      .then(response => {
          alert(response.data.message)}
      )
      .catch((err) => console.log(err));
  }

  const handleBackToLogin = () => {
    window.location.href = "./"
  }


  return(
    <div className='Login'>
        <Typography sx={{fontWeight:"bold", fontSize:"2rem", marginBottom: "2vh", opacity: "0.7"}}>Réinitialisation de mot de passe</Typography>
        <form>
              <TextField
                label="mot de passe"
                variant="outlined"
                type='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{ marginBottom: '20px', width: '100%' }}
              />
              <Button
                type="submit"
                variant="contained"
                style={{ backgroundColor: '#76b852', color: 'white', width: '100%' }}
                onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#5a9b3e'}
                onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#76b852'}
                onClick={(e) => handleReset(e)}
              >
                Changer le mot de passe
              </Button>
              <Button
                onClick={() => handleBackToLogin()}
                style={{ marginTop: '20px', color: '#76b852', width: '100%' }}
              >
                Retourner à la connexion
              </Button>
            </form>
    </div>
  );
  
}

export default Forgot;