import MUIDataTable from "mui-datatables";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers/";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { createTheme, ThemeProvider } from '@mui/material';
import { List, ListItem, Typography } from '@mui/material';

function ChantiersTab( {collec, handleDeleteChantier, handleChangeChantierDate} ) {

    const columns = [
    {
      name: 'nomChantier',
      label: 'Nom Chantier',
      options: {
        width: '50%'
      }
    },
    {
      name: 'dateD',
      label: 'Date Début',
      options: {
        width: '40%',
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowData = tableMeta.rowData;
          const nomChantier = rowData[0];
          const parsedValue = value ? dayjs(value) : null;
          return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={parsedValue}
                onChange={(date)=>handleChangeChantierDate(date, nomChantier)}
                format="YYYY/MM/DD"
              />
            </LocalizationProvider>
          );
        },
      },
    },
      {
        name: 'Inventaire',
        label: '',
        options: {
          width: '10%',
          customBodyRender: (value, tableMeta, updateValue) => {
            const chantierName = tableMeta.rowData[0];
            const chantier = collec.find(item => item.nomChantier === chantierName);
          
            const stockPortes = chantier ? chantier.stockPortes : [];
            const stockBaies = chantier ? chantier.stockBaies : [];


            return (
              <List dense={true}>
                    {stockPortes.map((porte, index) => (
                        <ListItem key={"porte" + index}>
                            <Typography variant="body2" className="inventairePortes inventaire">
                                {porte.id + " | " + porte.ref}
                            </Typography>
                        </ListItem>
                    ))}
                    {stockBaies.map((baie, index) => (
                        <ListItem key={"baie" + index}>
                            <Typography variant="body2" className="inventaireBaies inventaire">
                                {baie.id + " | " + baie.ref}
                            </Typography>
                        </ListItem>
                    ))}
                </List>
            );
          },
      },
    },
      {
        name: '',
        label: '',
        options: {
          width: '10%',
          customBodyRender: (value, tableMeta, updateValue) => {
            const rowData = tableMeta.rowData;
            const nomChantier = rowData[0];
            return (
              <IconButton onClick={() => handleDeleteChantier(nomChantier)}>
                <DeleteIcon color="error"/>
              </IconButton>
            );
          },
      },
    }
    
  ];

  const options = {
    filterType: 'textField',
    responsive: 'standard',
    selectableRows: 'none',
    sortOrder: {
      name: 'dateD',
      direction: 'desc',
    },
  };

  const customTheme = createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          head : {
            textTransform: 'none',
            fontWeight: 'bold',
          }
        }
      }
    }
  });
    
  return (
    <div className="StockTab">
      <ThemeProvider theme={customTheme}>
        <MUIDataTable title={"Chantiers"} data={collec} columns={columns} options={options} />
      </ThemeProvider>
    </div>
  );
}

export default ChantiersTab;
