import './Chantiers.css';
import ChantiersTab from './ChantierTab/ChantiersTab';
import CreateChantier from './CreateChantier/CreateChantier';

function Chantiers( {collecChantiers, setCollecChantiers, addChantier, handleDeleteChantier, handleChangeChantierDate, getChantiersNameList}) {
  
  return (
    <div className="Stock">
      <main>
        <CreateChantier 
            collec={collecChantiers} 
            addChantier={addChantier}
            getChantiersNameList={getChantiersNameList} />
        <ChantiersTab 
          collec={collecChantiers}
          setCollec={setCollecChantiers}
          handleChangeChantierDate={handleChangeChantierDate}
          handleDeleteChantier={handleDeleteChantier}/>
      </main>
      
    </div> 
  );
}

export default Chantiers;
