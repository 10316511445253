import './Stock.css';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import HelpIcon from '@mui/icons-material/Help';
import StockTab from './StockTab/StockTab';
import { useState } from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import * as React from 'react';
import { BACKEND } from '../../utils';

function Stock( {findChantier, getChantiersNameList } ) { 

  const [porteBtn, setPorteBtn] = useState("contained");
  const [baieBtn, setBaieBtn] = useState("outlined");
  const [stockSelector, setStockSelector] = useState("PORTES");

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
      padding: 10
    },
  }));

  //handle clicks on Portes or Baies buttons
  function handleClickPB( value ) {
    
    if (value === "PORTES") {
      setPorteBtn("contained");
      setBaieBtn("outlined");
    } else if (value === "BAIES") {
      setPorteBtn("outlined");
      setBaieBtn("contained");
    }
    setStockSelector(value);
  }

  const handleArchive = () => {
    let confirmation = window.prompt("Vous allez archiver tout les éléments marqués comme sortis.\nTapez 'CONFIRMER' pour continuer.") 
    if(confirmation !== "CONFIRMER"){
      return;
    }else{
      fetch(BACKEND + "/api/stock/archives/archive" , {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          },
        }).then(() => {
          window.location.reload();
        }).catch((err) => console.log(err));
        
    }
  }

  return (
    <div className="Stock">
      <main>
        <div className='stockUtils'>
          <ButtonGroup sx={{ml: 2}} aria-label="button group" >
            <Button onClick={e=>handleClickPB("PORTES")} variant={porteBtn}>PORTES</Button>
            <Button onClick={e=>handleClickPB("BAIES")} variant={baieBtn}>BAIES</Button>
          </ButtonGroup>
          <ButtonGroup sx={{textAlign:"center", display: "flex", alignItems: "center"}}>
            <Button sx={{mr:4}} onClick={() => handleArchive()} variant='contained'>Archiver portes et baies</Button>
            <HtmlTooltip
              title={
                <React.Fragment>
                    <Typography sx={{display: "flex",flexDirection: "row"}} color="inherit">
                      <div className='box green'/> En stock, non attribué
                    </Typography>
                    <Typography sx={{display: "flex",flexDirection: "row"}} color="inherit">
                      <div className='box yellow'/> En stock, attribué
                    </Typography>
                    <Typography sx={{display: "flex",flexDirection: "row"}} color="inherit">
                      <div className='box grey'/> En attente de reception
                    </Typography>
                    <Typography sx={{display: "flex",flexDirection: "row"}} color="inherit">
                      <div className='box red'/> En attente de reception, attribué 
                    </Typography>
                </React.Fragment>
              }
            >
              <HelpIcon sx={{mr: 2, color: "#76b852"}} />
            </HtmlTooltip>
          </ButtonGroup>
        </div>

        <StockTab 
            stockSelector={stockSelector} 
            findChantier={findChantier} 
            chantiersNameList={getChantiersNameList} 
          />
        
        </main>
      
    </div> 
  );
}


export default Stock;
