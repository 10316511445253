import { Button, TextField, Typography } from '@mui/material';
import './Login.css';
import React, { useState } from 'react';
import { BACKEND } from '../../utils';

function Login({setUser}) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [renderSelector, setRenderSelector] = useState('login');
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const resetErrors = () => {
    setEmailError(false);
    setPasswordError(false);
  }

  const handleLogin = (e) => {
    e.preventDefault();
    resetErrors();
    fetch(BACKEND + '/api/auth/signin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            email: username,
            password: password,
        }),
      })
      .then(response => 
        response.json().then(data => ({
            data: data,
            status: response.status
       })))
      .then(response => {
        
        if(response.status !== 200){
          if(response.status === 404){
            setEmailError(true);
          }else if (response.status === 401){
            setPasswordError(true);
          }
          alert(response.data.message)
        }else{
          setUser({
            username: response.data.username,
            roles : response.data.roles,
            accessToken: response.data.accessToken,
            date: new Date(),
          });
        }
      });
  }

  const handleForgotPassword = (e) => {
    e.preventDefault();
    alert("Sending reset link to " + username);
    fetch(BACKEND + "/api/auth/forgot" , {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        },
      body: JSON.stringify({
        email: username
        })
      }).catch((err) => console.log(err));
  }

  const render = () => {
    if(renderSelector === "login"){
        return (
            <form>
              <TextField
                error={emailError}
                label="Email"
                variant="outlined"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                style={{ marginBottom: '20px', width: '100%' }}
              />
              <TextField
                error={passwordError}
                label="Mot de passe"
                variant="outlined"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{ marginBottom: '20px', width: '100%' }}
              />
              <Button
                type="submit"
                variant="contained"
                style={{ backgroundColor: '#76b852', color: 'white', width: '100%' }}
                onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#5a9b3e'}
                onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#76b852'}
                onClick={(e) => handleLogin(e)}
              >
                Se Connecter
              </Button>
              <Button
                onClick={() => setRenderSelector('forgot password')}
                style={{ marginTop: '20px', color: '#76b852', width: '100%' }}
              >
                Mot de passe oublié ?
              </Button>
            </form>
        );
      }else if (renderSelector === "forgot password"){
        return (
            <form>
              <TextField
                label="Email"
                variant="outlined"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                style={{ marginBottom: '20px', width: '100%' }}
              />
              <Button
                type="submit"
                variant="contained"
                style={{ backgroundColor: '#76b852', color: 'white', width: '100%' }}
                onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#5a9b3e'}
                onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#76b852'}
                onClick={(e) => handleForgotPassword(e)}
              >
                Réinitialiser
              </Button>
              <Button
                onClick={() => setRenderSelector('login')}
                style={{ marginTop: '20px', color: '#76b852', width: '100%' }}
              >
                Retourner à la connexion
              </Button>
            </form>
        );
      }  
  }


  return(
    <div className='Login'>
        <Typography sx={{fontWeight:"bold", fontSize:"2rem", marginBottom: "2vh", opacity: "0.7"}}>ACR Stock 2023</Typography>
        {render()}
    </div>
  );
  
}

export default Login;