import './StockTab.css';
import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import { Autocomplete, IconButton, TextField } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { BACKEND } from '../../../utils';
function StockTab( {stockSelector, findChantier, chantiersNameList } ) {

  const [focusedComment, setFocusedComment] = useState(null);
  const [initialCommentText, setInitialdCommentText] = useState("");
  const [newComment, setNewComment] = useState("");

  useEffect(() =>{
    console.log(initialCommentText)
    
  }, [initialCommentText]);

  const colorBackgroundID = (value, tableMeta) => {
    const rowData = tableMeta.rowData;
    const inStock = rowData[4];
    const hasChantier = !(rowData[3] === null);
    const sortieStk = rowData[5];

    let backgroundColor;
    if(sortieStk){
      backgroundColor = '#af90ee';
    } else if (inStock && !hasChantier) {
        backgroundColor = '#90ee90'; // light green
    } else if(hasChantier && !inStock){
      backgroundColor = '#f75c5c'; //light red
    } else if (hasChantier && inStock) {
        backgroundColor = '#ffff66'; // yellow
    } else if(!hasChantier && !inStock){
        backgroundColor = '#a8a8a8'; // grey
    }

    return (
      <div style={{ backgroundColor}} className='DataTableID'>
            {value}
      </div>
    )

  }
  
  const columns = [{
      name: 'id',
      label: 'Identifiant',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return colorBackgroundID(value, tableMeta);
        }
      }
    },
    {
      name: 'ref',
      label: 'Référence',
    },
    {
      name: 'occas',
      label: 'Occas.',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Checkbox color="success" disabled readOnly checked={value}></Checkbox>
          );
        }
      }
    },
    {
      name: 'chantierNomChantier',
      label: 'Chantier',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const ID = tableMeta.rowData[0];
          let date = chantierValues[ID]?.date;
          let parsedDate = date ? dayjs(date).format("YYYY/MM/DD") : "Date de début non définie";
          return (
            <div>
              <Autocomplete 
                value={chantierValues[ID]?.name || value}
                onChange={(event, newValue) => {
                  handleChangeChantier(ID, newValue);
                }}
                disablePortal 
                options={chantiersNameList()} 
                renderInput={(params) => <TextField sx={{ minWidth: "8vw" }} {...params} label="" />}
                isOptionEqualToValue={(option, value) => true}
              />
              <div className='dateDisplay'>{parsedDate}</div>
            </div>
          );
        }
      }
    },
    {
      name: 'inStock',
      label: 'En Stock',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let rowData = tableMeta.rowData;
          let ID = rowData[0];
          
          return (
            <Checkbox color="success" checked={value} onClick={() => handleInStock(ID, !value)}></Checkbox>
          );
        }
      }
    },
    {
      name: 'sortiestk',
      label: 'Sortie STK',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let rowData = tableMeta.rowData;
          let ID = rowData[0];
          
          return (
            <Checkbox color="success" checked={value} onClick={() => handleSortieSTK(ID, !value)}></Checkbox>
          );
        }
      }
    },
    {
      name: 'comment',
      label: 'Commentaire',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          
          let rowData = tableMeta.rowData;
          let ID = rowData[0];

          let displayValue = (ID === focusedComment) ? newComment : value;

          if(value === null || value === ""){
            return (
              <div className='commentCellEmpty'>
              <TextField 
                    value="Ajoutez un commentaire" 
                    sx={{ minWidth: "15vw" }} 
                    multiline 
                    fullWidth 
                    rows={2}
                    onChange={(e) => handleChangeComment( ID, e.target.value)}
                    onClick={() => handleFocus(ID, value)}
              ></TextField>
              <div className='commentControl' hidden={ID !== focusedComment}>
                <IconButton color="success" variant="outlined" onClick={() => handleConfirmComment(ID)} ><CheckIcon/></IconButton>
                <IconButton color="error" variant="outlined" onClick={() => handleCancelComment(ID)}><ClearIcon/></IconButton>
              </div>
            </div>
            )
          }

          return (
            <div className='commentCell'>
              <TextField 
                    value={displayValue} 
                    sx={{ minWidth: "15vw" }} 
                    multiline 
                    fullWidth 
                    rows={2}
                    onChange={(e) => handleChangeComment( ID, e.target.value)}
                    onClick={() => handleFocus(ID, value)}
              ></TextField>
              <div className='commentControl' hidden={ID !== focusedComment}>
                <IconButton color="success" variant="outlined" onClick={() => handleConfirmComment(ID)} ><CheckIcon/></IconButton>
                <IconButton color="error" variant="outlined" onClick={() => handleCancelComment(ID)}><ClearIcon/></IconButton>
              </div>
            </div>
          );
        }
      }
      
    },
    {
      name: 'sortie',
      label: 'Installée',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let rowData = tableMeta.rowData;
          let ID = rowData[0];
          const boolValue = (value !== 0);

          return (
            <Checkbox color="success" checked={boolValue} onClick={() => handleSortie(ID, value)}></Checkbox>
          );
        } 
      }
    }
  ]


  const options = {
    filterType: 'textField',
    responsive: 'standard',
    selectableRows: 'none',
  }

  //Commentaire
  const handleFocus = (ID, value) => {
    if(focusedComment === ID){
      return;
    }
    if(focusedComment !== null) {
      alert("Terminez l'édition du commentaire en cours !");
      return;
    }
    setFocusedComment(ID);
    setInitialdCommentText(value);
    setNewComment(value);
  }

  const handleChangeComment = (ID, newValue) => {
    if(focusedComment === ID){
      setNewComment(newValue);
    }
  }

  const handleConfirmComment = (ID) => {
    if(stockSelector === "BAIES"){
      const newData = collecBaies.map((item) => {
        if (item.id === ID) {
          return { ...item, comment: newComment };
        }
        return item;
      });
      fetch(BACKEND + "/api/stock/baie/comment" , {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          },
        body: JSON.stringify({
          id: ID,
          comment: newComment
          })
        }).catch((err) => console.log(err));
      setCollecBaies(newData);
    }else if(stockSelector === "PORTES") {
      const newData = collecPortes.map((item) => {
        if (item.id === ID) {
          return { ...item, comment: newComment };
        }
        return item;
      });
      fetch(BACKEND + "/api/stock/porte/comment" , {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          },
        body: JSON.stringify({
          id: ID,
          comment: newComment
          })
        }).catch((err) => console.log(err));
      setCollecPortes(newData);
    }
    clearCommentFocus();
  }

  const handleCancelComment = (ID) => {
    if(stockSelector === "BAIES"){
      const newData = collecBaies.map((item) => {
        if (item.id === ID) {
          return { ...item, comment: initialCommentText };
        }
        return item;
      });

      setCollecBaies(newData);
    }else if(stockSelector === "PORTES") {
      const newData = collecPortes.map((item) => {
        if (item.id === ID) {
          return { ...item, comment: initialCommentText };
        }
        return item;
      });
      setCollecPortes(newData);
    }
    clearCommentFocus();
  }

  const clearCommentFocus = () => {
    setFocusedComment(null);
    setNewComment("");
    setInitialdCommentText("");
  }
  
  //SORTIE
  const handleSortie = (ID, value) => {
    const newValue = value ? 0 : 1;
    if (stockSelector === "BAIES") {
      const newDataset = collecBaies.map((item) => {
        if (item.id === ID) {
          return {...item, sortie: newValue}
        }
        return item;
      })
      fetch(BACKEND + "/api/stock/baies/sortie" , {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          },
        body: JSON.stringify({
          id: ID,
          value: newValue
          })
        }).catch((err) => console.log(err));

      setCollecBaies(newDataset);
    } else if (stockSelector === "PORTES") {
      const newDataset = collecPortes.map((item) => {
        if (item.id === ID) {
          return {...item, sortie: newValue}
        }
        return item;
      })
      
      fetch(BACKEND + "/api/stock/portes/sortie", {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            },
          body: JSON.stringify({
            id: ID,
            value: newValue
            })
        }).catch((err) => console.log(err));
        
        setCollecPortes(newDataset);
    }
    

  }
  //SORTIE STOCK ==================================================
  const handleSortieSTK = (ID, value) => {
    const text = value ? "SORTIE DU STOCK" : "PAS SORTIE DU STOCK";
    const confirmed = window.confirm("Confirmez le changement de l'état de " + ID + " à " + text)
    if (!confirmed){
      return;
    }
    
    if(stockSelector === "BAIES"){
      const newData = collecBaies.map((item) => {
        if (item.id === ID) {
          return { ...item, sortiestk: value };
        }
        return item;
      });

      fetch(BACKEND + "/api/stock/baies/sortieStk", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          },
        body: JSON.stringify({
          id: ID,
          value: value
          })
        }).then((res) => res.json())
        .catch((err) => console.log(err));
      setCollecBaies(newData);
    }else if(stockSelector === "PORTES") {
      const newData = collecPortes.map((item) => {
        if (item.id === ID) {
          return { ...item, sortiestk: value };
        }
        return item;
      });
      fetch(BACKEND + "/api/stock/portes/sortieStk", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          },
        body: JSON.stringify({
          id: ID,
          value: value
          })
        }).then((res) => res.json())
        .catch((err) => console.log(err));
      setCollecPortes(newData);
    }
  }
  
  //STOCK =============================================================
  const handleInStock = (ID, value) => {
    const text = value ? "EN STOCK" : "PAS EN STOCK";
    const confirmed = window.confirm("Confirmez le changement de l'état de " + ID + " à " + text)
    if (!confirmed){
      return;
    }
    
    if(stockSelector === "BAIES"){
      const newData = collecBaies.map((item) => {
        if (item.id === ID) {
          return { ...item, inStock: value };
        }
        return item;
      });

      fetch(BACKEND + "/api/stock/baies/inStock", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          },
        body: JSON.stringify({
          id: ID,
          value: value
          })
        }).then((res) => res.json())
        .catch((err) => console.log(err));
      setCollecBaies(newData);
    }else if(stockSelector === "PORTES") {
      const newData = collecPortes.map((item) => {
        if (item.id === ID) {
          return { ...item, inStock: value };
        }
        return item;
      });
      fetch(BACKEND + "/api/stock/portes/inStock", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          },
        body: JSON.stringify({
          id: ID,
          value: value
          })
        }).then((res) => res.json())
        .catch((err) => console.log(err));
      setCollecPortes(newData);
    }
  }

  const handleChangeChantier = (ID, newChantier) => {
    let text = "";
    if(newChantier!==null){
      text = "Confirmez le changement de chantier pour " + ID + " => " + newChantier.label;
    }else{
      text = "Confirmez la suppression du chantier pour " + ID;
    }

    const confirmed = window.confirm(text)
    if (!confirmed){
      return;
    }
    if(newChantier === null){
      setChantierValues((prevState) => ({
        ...prevState,
        [ID]: { name: null, date: null },
      }));
    }else{
      const chantierDate = findChantier(newChantier.label);
      setChantierValues((prevState) => ({
        ...prevState,
        [ID]: { name: newChantier, date: chantierDate },
      }));
    }

    

    let chantierLabel = newChantier ? newChantier.label : null;

  if(stockSelector === "BAIES"){
    const newData = collecBaies;
    const rowNumber = newData.findIndex((item) => item.id === ID);
    newData[rowNumber].chantierNomChantier = newChantier;
    fetch(BACKEND + "/api/stock/baies/chantier", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        },
      body: JSON.stringify({
        id: ID,
        chantier: chantierLabel
        })
      }).then((res) => res.json())
      .catch((err) => console.log(err));
      
    setCollecBaies(newData);
  }else if (stockSelector === "PORTES"){
    const newData = collecPortes;
    const rowNumber = newData.findIndex((item) => item.id === ID);
    newData[rowNumber].chantierNomChantier = newChantier;
    fetch(BACKEND + "/api/stock/portes/chantier", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        },
      body: JSON.stringify({
        id: ID,
        chantier: chantierLabel
        })
      }).then((res) => res.json())
      .catch((err) => console.log(err));

    setCollecPortes(newData);
  }
  }
  // PORTES
  
  const [collecPortes, setCollecPortes] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch(BACKEND + '/api/stock/portes');
      const data = await response.json();
      setCollecPortes(data); 
    }
    fetchData();
  }, []);

  //BAIES
  const [collecBaies, setCollecBaies] = useState();

  useEffect(() => {
    async function fetchData() {
      const response = await fetch(BACKEND + '/api/stock/baies');
      const data = await response.json();
      setCollecBaies(data);
    }
    fetchData();
  }, []);

  const [tableTitle, setTableTitle] = useState("Stock portes");
  const [collec, setCollec] = useState();

  useEffect(() => {
    if(stockSelector === "BAIES"){
      setTableTitle("Stock Baies");
      setCollec(collecBaies);
    }else if( stockSelector === "PORTES"){
      setTableTitle("Stock Portes");
      setCollec(collecPortes);
    }
  }, [stockSelector, collecBaies, collecPortes]);

  const [chantierValues, setChantierValues] = useState({})

  useEffect(() => {
    if(!collec){
      return;
    }
    const newChantierValues = collec.reduce((acc, item) => {
      const date = findChantier(item.chantierNomChantier);
      acc[item.id] = { name: item.chantierNomChantier, date };
      return acc;
    }, {});
    setChantierValues(newChantierValues);
  }, [collec, findChantier]);
  

  if (!collec || collec.length === 0) {
    return <h1>Chargement...</h1>;
  }else{
    console.log(collec)
  }
  return (
    <div className="StockTab">
          
      <MUIDataTable title={tableTitle} data={collec} columns={columns} options={options}/>
    </div> 
  );
}

export default StockTab;
