import React, { useState } from 'react';
import './Header.css';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LogoutIcon from '@mui/icons-material/Logout';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';


function Header({setDisplay, user, setUser, updateChantierList}) {

  const [anchorEl, setAnchorEl] = useState(null);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleHeaderClick(e) {
    setDisplay(e.target.id);
    if(e.target.id === "chantiers"){
      updateChantierList()
    }
    document.querySelectorAll(".menuOption").forEach((li) => {
      li.style.backgroundColor="#76b852";
    });
    document.getElementById(e.target.id).style.backgroundColor="#5a9b3e";
  }

  function handleLogoutClick() {
    setUser(null);
    localStorage.clear();
    handleClose();
  }

  return (
    <div className="Header">
      <header className='myHeader' >
        <nav >
          <ul>
            <li class="menuOption" id="stock" onClick={(e) => handleHeaderClick(e)} >Stock</li>
            <li class="menuOption" id="chantiers" onClick={(e) => handleHeaderClick(e)}>Chantiers</li>
            <li class="menuOption" id="commandes" onClick={(e) => handleHeaderClick(e)}>Commandes</li>
            <li class="menuOption" id="archives" onClick={(e) => handleHeaderClick(e)}>Archives</li>
          </ul>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <Avatar 
              className='AvatarMenu'
              onClick={handleClick} 
              sx={{bgcolor: "#8947AD",margin: '0 10px', cursor:'pointer'}}
              onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#A06BBD'}
              onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#8947AD'}
            >
              {user && user.username && user.username[0].toUpperCase()}
            </Avatar>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem disabled style={{ opacity: 1 }}>
                <Typography className='menutitle'> ACR Stock {new Date().getFullYear()}</Typography>
              </MenuItem>
              <MenuItem disabled style={{ opacity: 1 }}>
                <Typography variant="inherit">utilisateur : {user.username}</Typography>
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleLogoutClick} >
                
                <ListItemIcon>
                  <LogoutIcon color="red" fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">Déconnexion</Typography>
              </MenuItem>
            </Menu>
          </div>
        </nav>
      </header>
    </div>
  );
}

export default Header;